import * as React from "react"
import Helmet from "react-helmet"
import Layout from "../common/layout.js"
import Seo from "../common/seo.js"
import "tachyons"

const Terms = () => (
  <Layout>
    <Seo title="Algemene Voorwaarden" description="" />
    <Helmet>
      <meta name="robots" content="noindex, nofollow" />
    </Helmet>
    <div className="bg-washed-red pv5 flex flex-column items-center">
      <h1 className="db f1 display fw1">Algemene Voorwaarden</h1>
      <p className="db sans-serif f5 tracked ttu tc">
        Ingangsdatum: 1 augustus 2023
      </p>
    </div>
    <div className="lh-copy f4 serif mw8 center">
      <h1 className="sans-serif">Flex and Relax</h1>
      <ul>
        <li>
          De overeenkomst komt tot stand door het afgeven of toesturen van het
          ingevulde en ondertekende inschrijfformulier, of door het mondeling
          aanmelden van een losse les/workshop.
        </li>
        <li>
          Deelname aan de lessen geschiedt volledig op eigen risico van de
          deelnemer.
        </li>
        <li>
          Elke cursist is verplicht lichamelijke klachten voor de les te melden
          aan de docent. Deelneming is alleen mogelijk indien uw gezondheid geen
          belemmering vormt. Neem bij twijfel contact op met uw huisarts.
        </li>
        <li>Lesgeld dient voor aanvang van de lessen te zijn voldaan.</li>
        <li>
          Terugstorten van het lesgeld van de gemiste lessen is niet mogelijk.{" "}
        </li>
        <li>
          Bij afwezigheid van de docent regelt deze vervanging. Is vervanging
          niet mogelijk, dan zal het lesgeld teruggestort worden.{" "}
        </li>
        <li>
          Flex and Relax behoudt zich de vrijheid voor de algemene voorwaarden,
          de lestijden, locatie en data te allen tijde te wijzigen.
        </li>
        <li>
          Jaarlijks kunnen de prijzen van Flex and Relax verhoogd worden.
        </li>
        <li>
          Het is mogelijk om een proefles te volgen. Hiervoor wordt wel het
          reguliere lesgeld gevraagd.
        </li>
      </ul>
      <h2>Raja Yoga</h2>
      <ul>
        <li>
          Een strippenkaart van 10 lessen is 12 weken geldig.
        </li>
        <li>
          Een les kan tot zes uur van tevoren worden afgezegd. Voor een les die om 19.00 begint is dit dus uiterlijk 13.00.
        </li>
        <li>
          Bij niet tijdig afzeggen van een les wordt een strip in rekening gebracht.
        </li>
        <li>
          Als er na twaalf weken nog ongebruikte strippen zijn vervallen deze automatisch.
        </li>
        <li>
          Er wordt geen geld teruggegeven voor eenmaal verkochte strippenkaarten.
        </li>
        <li>
          Wanneer een les uitvalt wordt de geldigheidsperiode van je strippenkaart automatisch met een week verlengd.
        </li>
        <li>
          Bij een ziekteperiode langer dan een maand van de cursist wordt gezamenlijk naar een passende oplossing gezocht.
        </li>
      </ul>
      <h2>Zwangerschapsyoga</h2>
      <ul>
        <li>
          De deelnemer dient het verschuldigde lesgeld uiterlijk de dinsdag voor de
          aanvang van de cursus te betalen middels een overschrijving naar NL42
          KNAB 0255 3356 44 ten name van Flex and Relax.
        </li>
        <li>
          Bij het missen van een les is de mogelijkheid om de les, dit in
          overleg, aan het einde van de cursusperiode in te halen.
        </li>
      </ul>
      <h2>Losse lessen/workshops</h2>
      <ul>
        <li>
          De deelnemer dient het verschuldigde lesgeld uiterlijk 2 dagen voor de start van
          de cursus start te betalen middels overschrijving naar NL42 KNAB 0255
          3356 44.
        </li>
        <li>
          Afberichten is tot uiterlijk 48 uur voor aanvang van de workshop
          mogelijk. Daarna is de terugbetaling van de lesgeld niet meer
          mogelijk. De lessen kunnen niet worden ingehaald.{" "}
        </li>
      </ul>
      <h2>Massages</h2>
      <ul>
        <li>
          Afberichten is tot uiterlijk 24 uur mogelijk. Bij het niet opdagen
          voor een massage wordt er 50% in rekening gebracht.{" "}
        </li>
      </ul>
    </div>
  </Layout>
)

export default Terms
